import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getBeer } from '../../utils/CatalogService'
import { Beer } from './Beer'

import styles from './beer_showcase.module.css'

export const BeerShowcase : FunctionComponent = () => {

    const [beer, setBeer] = useState<undefined | Beer>();

    const { id } = useParams();

    useEffect(() => {
        console.log(id);
        getBeer(id ?? '').then(b => setBeer(b));
        
    }, [])

    return <div id={styles.root}>
        <div id={styles.imageSection}>
            <img className={styles.image} src={beer?.image}/>
        </div>
        <div id={styles.textSection}>
            <div className={styles.innerContainer}>
                <h3 className={styles.title}>{beer?.title}</h3>
                <p className={styles.subTitle}>{beer?.sub_title}</p>
                <p className={styles.description}>{beer?.long_description}</p>
            </div>
        </div>
    </div>
}