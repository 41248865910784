import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { Navbar } from './components/navbar/Navbar';
import { BeerChart } from './components/beer-chart/BeerChart';
import { BeerShowcase } from './components/beer-showcase/BeerShowcase';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={ <Navigate to="/beer" />} />
          <Route path="/beer" element={<BeerChart/>} />
          <Route path="/beer/:id" element={<BeerShowcase />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
