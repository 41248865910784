import { Beer } from "../components/beer-showcase/Beer";


export function getCatalog(){
    return fetch('/catalog.json')
        .then(response => response.json() as Promise<Beer[]>);
}

export function getBeer(id: string) : Promise<undefined | Beer>{
    return getCatalog().then(c => 
        {
            let beer;
            c.forEach(b => {
                if(b.id === id){
                    beer = b;
                }
            });
            
            return beer;
        });
}