import { FunctionComponent } from 'react'

import styles from './navbar.module.css';
import { Link } from 'react-router-dom';

export const Navbar : FunctionComponent = () => {
    return <div id={styles.navbar}>
            <Link to="/beer" id={styles.homeItem} className={styles.menuItem}>
                <p>Hjem</p>
            </Link>

            <Link to="/beer" className={styles.menuItem}>
                <p>Ølkart</p>
            </Link>
            <Link to="/about" id={styles.lastMenuItem} className={styles.menuItem}>
                <p>Om Oss</p>
            </Link>
    </div>
}