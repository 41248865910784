import { Grid } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { ChartItem } from "./chart-item/ChartItem";
import { ItemDescription } from "./chart-item/item_description";

import styles from './beer_chart.module.css'
import { Beer } from "../beer-showcase/Beer";
import { getCatalog } from "../../utils/CatalogService";

export const BeerChart : FunctionComponent = () => {

    const [catalog, setCatalog] = useState<Beer[]>()

    useEffect(() => {
        getCatalog().then(c => setCatalog(c));
    }, [])

    return <Grid container spacing={{xs: 0, sm: 0}} id={styles.root}>      
        {
            catalog?.map(b => {
                let itemDescription : ItemDescription ={
                    id: b.id,
                    title: b.title,
                    description: b.short_description,
                    thumbnail: "",
                    image: b.image
                }
                return <Grid key={b.id} className={styles.item} item xs={12} sm={6} md={4}>
                    <ChartItem itemDescription={itemDescription} />
                </Grid>
            })
        } 
        </Grid>
}