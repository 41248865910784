import { FunctionComponent } from "react";
import { ItemDescription } from "./item_description";

import styles from './chart_item.module.css'
import { useNavigate } from "react-router";

interface Props {
    itemDescription: ItemDescription
}



export const ChartItem : FunctionComponent<Props> = (props: Props) => {

    let navigate = useNavigate()

    const GoToBeer = () => {
        navigate(`${props.itemDescription.id}`)
    }

    return <div id={styles.root} onClick={GoToBeer}>
        <img className={styles.image} src={props.itemDescription.image}/>
        <div className={styles.description}>
            <h3>{props.itemDescription.title}</h3>
            <p>{props.itemDescription.description}</p>
        </div>
    </div>
}